<!--  -->
<template>
    <div class="row clearfix">
        <div>
            <div class="table-responsive" id="DomPdf">
                <h2 style="text-align: center "><b>用户个人信息基本表</b></h2>
                <h4>基本信息</h4>
                <el-row>
                    <el-descriptions :column="3" border :labelStyle="labelStyle" label-class-name="my-label"
                        content-class-name="my-content">
                        <el-descriptions-item v-for="item in basic" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-row>
                <h4>既往史</h4>
                <el-table :data="History" border :span-method="objectSpanMethod" :header-cell-style="tableHeaderCellStyle"
                    :cell-style="{ padding: '3px' }">
                    <el-table-column prop="item" label="既往史">
                    </el-table-column>
                    <el-table-column label="详细信息">
                        <el-table-column prop="label" label="项目">
                        </el-table-column>
                        <el-table-column prop="value" label="确诊时间">
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <el-table :data="Family" border :header-cell-style="tableHeaderCellStyle" :cell-style="{ padding: '3px' }">
                    <el-table-column prop="label" label="家族史">
                    </el-table-column>
                    <el-table-column prop="value" label="内容">
                    </el-table-column>
                </el-table>
                <el-table :data="Other" border :header-cell-style="tableHeaderCellStyle" :cell-style="{ padding: '3px' }">
                    <el-table-column prop="label" label="其它史">
                    </el-table-column>
                    <el-table-column prop="value" label="内容/(有/无)">
                    </el-table-column>
                </el-table>
                <h4>生活环境</h4>
                <el-row>
                    <el-descriptions :column="3" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in Environment" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-row>
                <h4 v-if="genderFlag">怀孕情况</h4>
                <el-row v-if="genderFlag">
                    <el-descriptions :column="3" border :labelStyle="labelStyle">
                        <el-descriptions-item v-for="item in Pregnant" :key="item.label" :label="item.label">
                            {{ item.value }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-row>
            </div>
            <br>
        <!-- <el-button type="primary" plain >修改个人信息</el-button>
            <el-button type="primary" plain >加入小屋</el-button>
                        <el-button type="primary" plain >既往病史录入</el-button> -->
            <el-button type="primary" plain @click="getPdf('#DomPdf')">下载个人信息表</el-button>
            <el-button type="primary" plain @click="dialogVisible = true">修改</el-button>
            <!-- <el-button type="primary" plain onclick="download()">下载个人信息表</el-button> -->
            <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" >
                <div>请前往【心狗健康服务——心狗云医生】小程序进行修改</div>
                <img style="zoom:0.8" src="../../../../src/assets/img/applet_QRcode.jpg" class="aligncenter"/>
                <div> 【操作步骤】：我的->个人信息->修改个人信息 </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
                </span>
            </el-dialog>

        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';


export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    // props传递属性
    props: {
        userId: Number,  // 【其他平台】读取的属性
    },
    data() {
        //这里存放数据
        return {
            uid: this.userId,
            // 基本信息
            basic: [
                { label: '姓名', value: 'xxx', name: 'realName' },
                // { label: '用户编号', value: 'xxx' ,name: 'uid'},
                { label: '年龄', value: '00', name: 'age' },
                { label: '性别', value: '-', name: 'gender' },
                { label: '身高(cm)', value: '-', name: 'height' },
                { label: '体重(kg)', value: '', name: 'weight' },
                { label: '血型', value: '', name: 'bloodType' },
                { label: '出生日期', value: '-', name: 'birthday' },
                { label: '身份证号', value: '0', name: 'idCard' },
                { label: '工作单位', value: '--', name: 'company' },
                { label: '邮箱', value: '', name: 'email' },  //user
                { label: '本人电话', value: '', name: 'phone' }, //user
                // { label: '联系人姓名', value: '' ,name: ''},
                // { label: '联系人电话', value: '' ,name: ''},
                { label: '居住地编码', value: '', name: 'areaCode' },
                { label: '详细居住地', value: '', name: 'areaAddress' },
                { label: '户籍所在地编码', value: '', name: 'censusRegisterCode' },
                { label: '详细户籍所在地', value: '', name: 'censusRegisterAddress' },
                { label: '常住类型', value: '', name: 'residentialType' },
                { label: '民族', value: '', name: 'nationality' },
                { label: '婚姻状况', value: '', name: 'marry' },
                { label: '教育程度', value: '', name: 'education' },
                { label: '职业', value: '', name: 'job' },
                { label: '健康状态自我评估', value: '', name: 'selfEvaluation' },
                { label: '心理状况', value: '', name: 'mentality' },
                { label: '医疗费用支付方式', value: '', name: 'insurancePaymentMode' },
                { label: '药物过敏史', value: '', name: 'allergyHistory' },
            ],
            // 既往史

            History: [
                // 疾病史diseaseHistory
                // { item: '疾病史', label: '高血压', value: '-' },
                // { item: '疾病史', label: '糖尿病', value: '-' },
                // { item: '疾病史', label: '冠心病', value: '-' },
                // { item: '疾病史', label: '慢性阻塞性肺疾病', value: '-' },
                // { item: '疾病史', label: '恶行肿瘤', value: '-' },
                // { item: '疾病史', label: '脑卒中', value: '-' },
                // { item: '疾病史', label: '严重精神障碍', value: '-' },
                // { item: '疾病史', label: '结核病', value: '-' },
                // { item: '疾病史', label: '肝炎', value: '-' },
                // { item: '疾病史', label: '其他法定传染病', value: '-' },
                // { item: '疾病史', label: '职业病', value: '-' },
                // { item: '疾病史', label: '其他', value: '-' },
                // { item: '疾病', label: '名称1', value: '-' },
            ],
            Family: [
                // 家族史familyDisease
                { item: '家族史', label: '父亲', value: '-', name: 'father' },
                { item: '家族史', label: '母亲', value: '-', name: 'mother' },
                { item: '家族史', label: '兄弟姐妹', value: '-', name: 'brotherAndSister' },
                { item: '家族史', label: '子女', value: '-', name: 'child' },
            ],
            Other: [
                // 其他史
                { item: '其他史', label: '暴露史', value: '-', name: 'exposureHistory' },
                { item: '其他史', label: '遗传病史', value: '-', name: 'inheriDeseaseHistory' },
                { item: '其他史', label: '残疾状况', value: '-', name: 'disability' },
            ],
            Environment: [
                { label: '厨房排风设施', value: '', name: 'ventilation' },
                { label: '燃料类型', value: '', name: 'fuel' },
                { label: '饮水', value: '', name: 'water' },
                { label: '厕所', value: '', name: 'toilet' },
                { label: '禽畜栏', value: '', name: 'corral' },
            ],
            Pregnant:[
                {label:'是否怀孕',value: '', name: 'isPregnant' },
                {label:'怀孕日期',value: '', name: 'PregnantDay' },
            ],
            genderFlag: false,
            htmlTitle: '个人信息表',
            labelStyle: {
                // 'width': '180px',
                'color': '#606266'
            },
            infoStyle: {
                'color': '#606266'
            },
            dialogVisible: false
        };
    },
    //监听属性 类似于data概念
    computed: {

    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getInformation() {
            // console.log(this.uid)
            this.$http({
                url: this.$http.adornUrl('/personal/info/app/getPersonalInfo'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看数据")
                    console.log(data)
                    let info_basic = data.info
                    let user_basic = data.user
                    let familyDisease = JSON.parse(info_basic.familyDisease) //家族史
                    let module_ls = [this.basic, this.Environment, this.History, this.Family, this.Other]
                    let name_ls = [info_basic, user_basic, familyDisease]
                    let gendermap = { 0: "女", 1: "男" }

                    console.log(info_basic["insurancePaymentMode"].replace(/\["/g, "").replace(/\"]/g, ""))

                    // 赋值
                    // 1.基本信息+家族史+其它史（这些的label都是固定的）
                    for (let k = 0; k < name_ls.length; k++) {
                        for (let i = 0; i < module_ls.length; i++) {
                            for (let j = 0; j < module_ls[i].length; j++) {
                                let name = module_ls[i][j]["name"]
                                if ((name in name_ls[k])) {
                                    if (name == 'gender') {
                                        if(name_ls[k][name]==1){
                                            this.genderFlag = false   // 性别标签，用于控制是否显示【怀孕情况】
                                        }
                                        module_ls[i][j]["value"] = gendermap[name_ls[k][name]]
                                    } else {
                                        if (typeof (name_ls[k][name]) == 'string') {
                                            // 去掉某些字符串中的[]"\
                                            module_ls[i][j]["value"] = name_ls[k][name].replace(/\[/g, "").replace(/\]/g, "").replace(/\\"/g, "").replace(/\"/g, "")
                                        } else {
                                            module_ls[i][j]["value"] = name_ls[k][name]
                                        }

                                    }
                                    if (!name_ls[k][name] || name_ls[k][name] == 'null') // 非空判断
                                    {
                                        module_ls[i][j]["value"] = "-"
                                        continue
                                    }
                                }
                            }
                        }
                    }
                    // 2.疾病、手术、外伤、输血（label不固定）
                    let diseaseHistory = JSON.parse(info_basic.diseaseHistory) //疾病
                    let operationHistory = JSON.parse(info_basic.operationHistory) //手术
                    let injuryHistory = JSON.parse(info_basic.injuryHistory) //外伤
                    let transfusionHistory = JSON.parse(info_basic.transfusionHistory) //输血
                    let obtainedData = [diseaseHistory, operationHistory, injuryHistory, transfusionHistory]
                    let formItem = ['疾病', '手术', '外伤', '输血']
                    var History_list = new Array();
                    // 把key值写进label，把时间写进value
                    for (let k = 0; k < obtainedData.length; k++) {
                        let key = Object.keys(obtainedData[k])
                        for (let i = 0; i < key.length; i++) {
                            let obj = { item: formItem[k], label: key[i], value: obtainedData[k][key[i]] }
                            History_list.push(obj)
                            this.getSpanArr(History_list) //按item字段合并后的数组
                        }
                    }

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },

        // 表头样式
        tableHeaderCellStyle() {
            return "background:#fafafa"
        },
        // 既往史合并方法
        // step1.合并名称相同的列-设置单元格（CSDN https://blog.csdn.net/weixin_46565787/article/details/122084219）
        getSpanArr(list) {
            // 先给所有的数据都加一个v.rowspan = 1
            list.forEach(item => {
                item.rowspan = 1;
            });
            // 双层循环
            for (let i = 0; i < list.length; i++) {
                for (let j = i + 1; j < list.length; j++) {
                    //此处可根据相同字段进行合并，此处是根据的id
                    if (list[i].item === list[j].item) {
                        list[i].rowspan++;
                        list[j].rowspan--;
                    }
                }
                // 这里跳过已经重复的数据
                i = i + list[i].rowspan - 1;
            }
            this.History = list;
        },
        // step2. 设置el-table :span-method="objectSpanMethod"
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return {
                    rowspan: row.rowspan,
                    colspan: 1
                };
            }
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.$store.state.module.moduleName === 'women_baby') {
            this.uid = this.$store.state.women_baby.uid
        }
        console.log(this.uid)


    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        console.log(this.$route.query);
        if (this.$route.query.index) {
            console.log("看看是否传参：");
            console.log(this.$route.query.index);
            this.uid = this.$route.query.index

        }
        this.getInformation();
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style >
.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 6px 10px;
}

.aligncenter {
    clear: both;
    display: block;
    margin: auto;
}
</style>